<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <button class="btn btn-primary"
                                        v-if="data.permission.canCreate"
                                        @click="modalOpen()">
                                    {{ translations?.add_new }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <data-table :translations="translations" :options="dataList.options"
                                :data-set="data.dataList"
                                :page-size="data.pageSize"
                                @change-action="changeAction"
                                @change-status="changeStatus"
                                @pagination="pagination"
                                @limit-change="limitChange"/>
                </div>
            </div>
        </div>
    </section>

    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="addUserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <add-edit-user v-if="dataList.isModalActive"
                           :form-error="dataList.formError"
                           :form-data="dataList.formData"
                           @submit-form="submitForm"/>
        </div>
    </div>
    <!-- /Modal -->

</template>

<script>
import {onMounted, reactive, watch, computed} from "vue";
import HelperFunction from "@/common/helpers";
import DataTable from '@/common/components/datatable/index';
import AddEditUser from "@/views/auth/administrator/user/AddEditUser";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default {
    name: "CompanyUsers",
    components: {AddEditUser, DataTable},
    setup() {
        const store = useStore();
        const route = useRoute();
        const {
            data,
            deleteListData,
            getAllData,
            storeFormData,
            showToast,
            editFormData,
            selectedCompany,
            permissionResolver
        } = HelperFunction();

        /** watch **/
        permissionResolver(route.path);
        /** watch end **/

        const translations = computed(() => {
            return store.state.translations;  
        });

        let dataList = reactive({
            isModalActive: false,
            formError: {},
            formData: {},
            options: {
                name: translations.value?.users_list || 'Users List',
                columns: [
                    {
                        title: translations.value?.name || 'Name',
                        type: 'text',
                        key: 'name'
                    },
                    {
                        title: translations.value?.email || 'Email',
                        type: 'text',
                        key: 'email'
                    },
                    {
                        title: translations.value?.username || 'Username',
                        type: 'text',
                        key: 'username'
                    },
                    {
                        title: translations.value?.mobile || 'Mobile',
                        type: 'text',
                        key: 'mobile'
                    },
                    {
                        title: translations.value?.status || 'Status',
                        type: 'radio',
                        key: 'status',
                        show: true,
                        modifier: (value, row) => {
                            return value === 'Active' ? 1 : 0;
                        },
                        modifier2: (value, row) => {
                            return !data.permission.status;
                        }
                    }
                ],
                showAction: true,
                action: [
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: data.permission.canEdit
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: data.permission.canDelete
                    }
                ]
            }
        });



        onMounted(() => {
            getData();
            $('#showModal').on('hidden.bs.modal', (e) => {
                dataList.isModalActive = false;
            });
        })
        const getData = (options = {}) => {
            const company_id = selectedCompany.value.id ? selectedCompany.value.id : null;
            data.setURL = company_id ? `${vueConfig.User.UsersEndPoint}?company_id=${company_id}`
                : vueConfig.User.UsersEndPoint;
            getAllData(data.setURL, options);
        }

        const modalOpen = () => {
            if (dataList.formData.id) {
                dataList.formData = []
            }
            dataList.isModalActive = true;
            $('#showModal').modal('show');
        }

        const submitForm = async (formData) => {
            const action = {
                url: vueConfig.User.UsersEndPoint,
                modalId: 'showModal'
            };
            await storeFormData(action, formData,false,false,true)
                .then(() => {
                    getData()
                })
                .catch((error) => {
                    if (error.response) {
                        dataList.formError = error.response.data.errors;
                    }
                });
        }

        /** emit form data table **/
        const changeAction = async (action, row) => {
            if (action.type === 'edit') {
                modalOpen();
                dataList.formData = row;
                const company_id = selectedCompany.value.id ? selectedCompany.value.id : null
                const editURL = company_id ? `${vueConfig.User.UsersEndPoint}/${row.id}?company_id=${company_id}`
                    : `${vueConfig.User.UsersEndPoint}/${row.id}?`;
                await editFormData({'url': editURL})
                    .then((res) => {
                        dataList.formData = res.data.data
                    })
            } else if (action.type === 'delete') {
                const company_id = selectedCompany.value.id ? selectedCompany.value.id : null
                await deleteListData(`${vueConfig.User.UsersEndPoint}/${row.id}?company_id=${company_id}`)
                .then(() => {
                    getData()
                });
            }
        }
        const pagination = (page) => {
            let options = {
                page: page
            };
            getData(options);
        }
        const limitChange = (limit) => {
            let options = {
                limit: limit
            };
            getData(options);
        }

        const changeStatus = async (column, row) => {
            let obj = {
                action: `${vueConfig.User.UserChangeStatusEndPoint}/${row.id}`,
                formData: {
                    _method: 'PUT',
                    company_id: selectedCompany.value.id ? selectedCompany.value.id : null
                }
            }
            await store.dispatch('changeStatus', obj)
                .then(res => {
                    getData();
                }).catch(error => {
                    showToast(error);
                })
        }

        return {
            data,
            dataList,
            modalOpen,
            submitForm,
            changeAction,
            pagination,
            limitChange,
            changeStatus,
            translations
        }
    }
}
</script>

<style scoped>

</style>
